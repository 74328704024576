<template>
    <div class="ex-content">
        <van-cell-group>
            <van-cell title="兑换方式"/>
            <van-radio-group v-model="radio">
                <van-cell title="微信零钱包" clickable @click="radio = '1'">
                    <template #icon="props">
                        <van-icon name="wechat-pay" size="24" color="#4FCB78"/>
                    </template>
                    <template #right-icon>
                        <van-radio name="1" checked-color="#FF98A0"/>
                    </template>
                </van-cell>
                <van-cell clickable @click="radio = '2'">
                    <template #title>
                        <span>支付宝余额</span>
                        <i v-if="accountInfo&&accountInfo.agentName">（授权姓名：{{accountInfo.agentName}}）</i>
                    </template>
                    <template #icon="props">
                        <van-icon name="alipay" size="24" color="#53B3F0"/>
                    </template>
                    <template #right-icon>
                        <van-radio name="2" checked-color="#FF98A0"/>
                    </template>
                </van-cell>
            </van-radio-group>
            <template v-if="radio === '2'">
                <van-field class="pad-left ali-count" v-model="aliCount" placeholder="输入支付宝账号">
                    <template slot="button">如何查看账号
                        <van-icon name="question" @click="showAliPayLayer = true"/>
                    </template>
                </van-field>
                <van-field class="pad-left" v-model="reAliCount" placeholder="再输入一次确认"
                           v-show="!aliCount ||  !accountInfo || accountInfo.aliAccount !== aliCount"/>
                <van-field v-if="!accountInfo.agentName" class="pad-left ali-count" v-model="userName" placeholder="请填写您提现所认证的真实姓名"/>
            </template>
            <van-button class="ivv-btn-submit" block color="#FF98A0" @click="confirmExchange">确认兑换</van-button>
        </van-cell-group>
        <van-popup class="alipay-layer" v-model="showAliPayLayer">
            <van-icon name="clear" @click="showAliPayLayer = false"/>
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20201106162310008.png"/>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "Exchange",
        data() {
            return {
                radio: '',
                aliCount: '',
                reAliCount: '',
                userName: '',
                goldNum: 0,//提现数量
                accountInfo: null,
                showAliPayLayer: false,
            }
        },
        created() {
            let num = Number(this.$route.params.num);
            if (isNaN(num)) {
                this.$dialog.alert({
                    title: '提示',
                    message: '数据错误，请返回重试'
                }).then(() => { window.history.back(-1) })
                .catch(() => { window.history.back(-1) })
            } else {
                this.goldNum = +num;
                this.getAccountInfo();
            }
        },
        methods: {
            getAccountInfo() {
                this.get("/PersonCenter/Balance/GetWithdrawalInfo?", {source: 2}, 2).then(res => {
                    if (res.code === 1) {
                        let data = res.response;
                        if (data) {
                            this.aliCount = data.aliAccount || '';
                            // this.userName = data.agentName || '';
                            this.accountInfo = data;
                        }
                    }
                })
            },
            confirmExchange() {
                let type = this.radio;
                if (!type) return this.$toast("请选择提现方式!");
                if (type === '2') {
                    if (!this.aliCount) return this.$toast("请填写您的支付宝账号!");
                    if (this.reAliCount !== this.aliCount && !(this.accountInfo && this.accountInfo.aliAccount === this.aliCount)) return this.$toast("填写的支付宝账号不一致!");
                    if (!this.userName && !this.accountInfo.agentName) return this.$toast("请填写您提现所认证的真实姓名!");
                }
                let payType = type === '1' ? 5 : 6;
                let accountName = type == 2 ? '支付宝账户' : '微信账户'
                this.$dialog.confirm({
                    title: '提示',
                    message: '是否确认兑换' + (this.goldNum / 10) + '元自助奖励，' + '兑换成功后钱直接到您的' + accountName + '中，为确保款项入账无误，请确认您的' + accountName + '正确！'
                }).then(() => {
                    this.$toast.loading({message: '提现中...', duration: 0, overlay: true});
                    this.post("/MVPuzzle/Mo/WithdrawalAccountGold", {
                        bolg: this.goldNum,
                        payType: payType,
                        accountStr: type === '1' ? '' : this.aliCount, //账号 如果payType=1 就是微信openid payType=2支付宝账号
                        nickName: this.userName || this.accountInfo.agentName,
                        headimgUrl: ''
                    }, true, 2).then(json => {
                        this.$toast.clear();
                        if (json.code === 1) {
                            this.$dialog.alert({title: '恭喜您', message: '提现成功'}).then(() => {
                                this.$router.back()
                            })
                        } else {
                            this.$dialog.alert({title: '提现失败', message: json.msg});
                        }
                    }).catch(() => {
                        this.$toast.clear();
                        this.$toast("提现失败")
                    })
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .ex-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        text-align: left;
        background-color: #F8F8F8;

        .van-cell-group {
            margin-top: 10px;
            padding-bottom: 40px;

            /deep/ .van-cell {
                .van-cell__title {
                    color: #999;

                    i {
                        font-style: normal;
                    }
                }

                .van-cell__value {
                    color: #666;

                    .van-field__button {
                        .van-button {
                            height: 22px;
                            border-radius: 22px;
                        }
                    }
                }

                .van-field__value {
                    .van-field__control:disabled {
                        color: #333;
                        -webkit-text-fill-color: #333;
                    }
                }

                .van-icon-wechat-pay, .van-icon-alipay {
                    padding-right: 3px;
                }
            }

            .ali-count {
                /deep/ .van-field__button {
                    color: #999;
                    display: flex;
                    align-items: center;
                    font-size: 12px;

                    .van-icon-question {
                        font-size: 16px;
                        padding-left: 5px;
                    }
                }
            }
        }

        .ivv-btn-submit {
            width: calc(100% - 60px);
            margin: 30px auto;
            height: 33px;
            border-radius: 33px;
        }

        .alipay-layer {
            width: 80%;
            overflow: hidden;
            border-radius: 6px;

            > img {
                width: 100%;
            }

            .van-icon-clear {
                position: absolute;
                top: 10px;
                right: 10px;
                color: #b4b2b2;
                font-size: 20px;
            }
        }
    }
</style>